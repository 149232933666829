import React, { Component } from "react";
import _data from "../../data";
import axios from "axios";
import AppConfig from "../../constants/config";
import {NotificationManager} from 'react-notifications';

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      disableContactBtn: true,
      contactBtnText: "Send Message",
      contact: {}
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.message = this.message.bind(this);
  }

  /**
   * When we click on Send button, changeBtnText function will help to change text
   * @param contactBtnText
   */
  changeBtnText = contactBtnText => {
    this.setState({ contactBtnText });
  };

  /**
   * Get all form data and set to the state
   * @param contactBtnText
   */
  handleFormValueChange(inputName, event) {
    let stateValue = {};
    stateValue[inputName] = event.target.value;
    this.setState(stateValue);
  }

  async handleSubmit(event) {
    try {
      event.preventDefault();
      const reqData = {
        name: this.state.name,
        email: this.state.email,
        message: this.state.message,
      }
      const {data} = await axios.post(`${AppConfig.baseUrl}/contact_inquiries/addEnquiry`, reqData);
      if(!data.error){
        NotificationManager.success('Enquiry sent successfully');
        this.setState({
          name: "",
          email: "",
          message: "",
          disableContactBtn: true,
          contactBtnText: "Send Message",
        })
      }
      else{
        throw new Error(data.title);
      }
    } catch (error) {
      NotificationManager.error(error.message || 'Something went wrong');
    }
  }

  message(error) {
    const messageBox = document.querySelector('#contact .message-box');
    if (messageBox.classList.contains("d-none")) {
      messageBox.classList.remove("d-none");
    }
    messageBox.classList.add("d-block");
    if(error) {
      if (messageBox.classList.contains("alert-success")) {
        messageBox.classList.remove("alert-success");
      }
      messageBox.classList.add("alert-danger");
      messageBox.innerHTML = 'Found error in the form. Please check again.';
    }
    else {
      if (messageBox.classList.contains("alert-danger")) {
        messageBox.classList.remove("alert-danger");
      }
      messageBox.classList.add("alert-success");
      messageBox.innerHTML = 'Form submitted successfully';
    }
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
    this.setState({
      contact: _data.contact
    });
  }

  componentDidUpdate(prevProps, prevState){
    if(prevState.email !== this.state.email || prevState.name !== this.state.name || prevState.message !== this.state.message){
      if(this.state.name && this.state.email && this.state.message){
        this.setState({
          disableContactBtn: false
        })
      }
      else{
        this.setState({
          disableContactBtn: true
        })
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <section id="contact" className="contact-us-section ptb-100">
            <div className="container">
                <div className="row justify-content-around">
                    <div className="col-12 pb-3 mb-4 message-box d-none alert alert-success"></div>
                    <div className="col-md-12 col-lg-5 mb-5 mb-md-5 mb-sm-5 mb-lg-0">
                        <div className="contact-us-form gray-light-bg rounded p-5">
                            <h4>Drop in a query</h4>
                            <form id="contactForm" className="contact-us-form" onSubmit={this.handleSubmit}>
                                <div className="form-row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="name" value={this.state.name} onChange={e => this.handleFormValueChange("name", e)} placeholder="Enter name" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <input type="email" className="form-control" name="email" value={this.state.email} onChange={e => this.handleFormValueChange("email", e)} placeholder="Enter email" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <textarea name="message" value={this.state.message} id="message" className="form-control" rows="7" cols="25" placeholder="Message" onChange={e => this.handleFormValueChange("message", e) }></textarea>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 mt-3">
                                        <button type="submit" className="btn btn-brand-02" id="btnContactUs" disabled={this.state.disableContactBtn} onClick={() => { this.changeBtnText("Sending..."); }}>
                                        {this.state.contactBtnText}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                        <div className="contact-us-content">
                            <h4>Ready to automate your lease accounting?</h4>
                            <p className="lead">We would love to showcase our platform and recommend tailored solutions based on your needs.</p>

                            <a href="/book_demo" className="btn btn-outline-brand-01 align-items-center">Book a Demo <span className="ti-arrow-right pl-2"></span></a>

                            <hr className="my-5" />

                            <ul className="contact-info-list">
                                <li className="d-flex pb-1">
                                    <div className="contact-icon mr-3">
                                        <span className={(this.state.contact.addressIcon) + " color-primary rounded-circle p-3"}></span>
                                    </div>
                                    <div className="contact-text">
                                        <h5 className="mb-1">{this.state.contact.addressTitle}</h5>
                                        <p>
                                          1230 Peachtree Street, Suite 1500, Atlanta, GA, 30309, USA.
                                        </p>
                                    </div>
                                </li>
                                <li className="d-flex pb-1">
                                    <div className="contact-icon mr-3">
                                        <span className={(this.state.contact.emailIcon) + " color-primary rounded-circle p-3"}></span>
                                    </div>
                                    <div className="contact-text">
                                        <h5 className="mb-1">{this.state.contact.emailTitle}</h5>
                                        <p>
                                          info@leasevista.com
                                        </p>
                                    </div>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
        </section>
      </React.Fragment>
    );
  }
}

export default ContactForm
