import React, { Component } from "react";
import _data from "../../data";
//import img1 from "../../../public/assets/img/Cheng_Mike.jpg";
//import img2 from "../../../public/assets/img/Pervost.jpg";


class Team extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testimonial: {}
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
    this.setState({
      testimonial: _data.testimonial
    });
  }

  render() {
    return (
      <React.Fragment>
        <section id="team" class="overview-block-ptb gray-light-bg ptb-100">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="heading-title section-heading text-center mb-4">
                            <h2 class="title iq-tw-6">Meet the Team</h2>
                            <div class="divider"></div>
                            <p>Meet the faces behind our company. Our team offers the most up-to-date, sustainable custom solutions to the problem.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-lg-4">
                        <div className="single-team-wrap bg-white text-center border rounded p-4 mt-4">
                            <img src="assets/img/MC.png" alt="team" width="120" className="img-fluid m-auto pb-4" />
                            <div className="team-content">
                                <h5 className="mb-0">Mike Cheng</h5>
                                <b><span style={{fontSize:'12px'}}>CEO</span></b>
                                <p className="mt-3">
                                  Mike, a former Senior Project Manager at the Financial Accounting Standards Board (FASB), is currently a Partner at Frazier & Deeter.
                                
                                  Passionate about helping people solve complex accounting and financial reporting issues, Mike conceived the idea for LeaseVista and is the CEO of LeaseVista.
                                
                                  Most recently, he worked on the FASB’s implementation team on revenue recognition (ASC Topic 606) and lease accounting (ASC Topic 842).
                                </p>
                                <ul className="list-inline social-list-default social-color icon-hover-top-bottom">
                                    <li className="list-inline-item">
                                        <a className="linkedin" href="https://www.linkedin.com/in/michael-cheng-07ab6056/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"  style={{color:'inherit'}}></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <div className="single-team-wrap bg-white text-center border rounded p-4 mt-4">
                            <img src="assets/img/VA.png" alt="team" width="120" className="img-fluid m-auto pb-4" />
                            <div className="team-content">
                                <h5 className="mb-0">Venkat Avasarala</h5>
                                <b><span style={{fontSize:'12px'}}>COO</span></b>
                                <p className="mt-3">
                                  Formerly the Head of Technology at Financial Accounting Foundation, Venkat is the COO of LeaseVista.
                                 
                                  He is also the founder and CEO of Acuvity Consulting, a management consulting firm providing services in Business Strategy and Technology solutions to clients.
                                
                                  He has an MBA from Carnegie Mellon University and a Masters in Computer Sciences from Villanova University.
                                  <br/><br/>
                                </p>
                                <ul className="list-inline social-list-default social-color icon-hover-top-bottom">
                                    <li className="list-inline-item">
                                        <a className="linkedin" href="http://www.linkedin.com/in/venkatavasarala" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"  style={{color:'inherit'}}></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <div className="single-team-wrap bg-white text-center border rounded p-4 mt-4">
                            <img src="assets/img/JP.png" alt="team" width="120" className="img-fluid m-auto pb-4" />
                            <div className="team-content">
                                <h5 className="mb-0">Jodi Prevost</h5>
                                <b><span style={{fontSize:'12px'}}>Advisor</span></b>
                                <p className="mt-3">
                                  Jodi Prevost is an experienced audit partner at Frazier & Deeter leading audit methodology.
                                  
                                  Graduated from Georgia Institute of Technology, Jodi has extensive lease accounting experience and specializes in audits of not-for-profit, professional services and manufacturing & distribution entities and middle-market real estate and country clubs.
                               
                                  Jodi ensures that LeaseVista is a collaborative tool for firms and their clients.
                                </p><br/>
                                <ul className="list-inline social-list-default social-color icon-hover-top-bottom">
                                    <li className="list-inline-item">
                                        <a className="linkedin" href="https://www.linkedin.com/in/jodi-prevost-cpa-81a6a01/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"  style={{color:'inherit'}}></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div class="row" style={{fontSize: '1rem'}}>
                    <div class="col-sm-6 col-lg-4 col-md-4">
                        <div class="iq-team">
                            <img src="assets/img/MC.png" alt="Mike Cheng" class="img-responsive center-block" style={{width:'50%'}} />
                            <div class="iq-team-info text-center">
                                <h4 class="iq-font-white iq-tw-5">Mike Cheng - CEO</h4>
                                <ul style={{textAlign:'center', padding:"0 40px", fontSize:"14px"}}>
                                  <li>
                                    Mike, a former Senior Project Manager at the Financial Accounting Standards Board (FASB), is currently a Partner at Frazier & Deeter.
                                 
                                    Passionate about helping people solve complex accounting and financial reporting issues, Mike conceived the idea for LeaseVista and is the CEO of LeaseVista.
                                  
                                    Most recently, he worked on the FASB’s implementation team on revenue recognition (ASC Topic 606) and lease accounting (ASC Topic 842).
                                  </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4"> 
                        <div class="iq-team">
                            <img src="assets/img/VA.png" alt="Venkat Avasarala" class="img-responsive center-block" style={{width:'50%'}}  />
                            <div class="iq-team-info text-center">
                                <h4 class="iq-font-white iq-tw-5">Venkat Avasarala - COO</h4>
                                <ul style={{textAlign:'center', padding:"0 40px", fontSize:"14px"}}>
                                  <li>
                                    Formerly the Head of Technology at Financial Accounting Foundation, Venkat is the COO of LeaseVista.
                                 
                                    He is also the founder and CEO of Acuvity Consulting, a management consulting firm providing services in Business Strategy and Technology solutions to clients.
                                  
                                    He has an MBA from Carnegie Mellon University and a Masters in Computer Sciences from Villanova University.
                                  </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-md-4 re-mt-30">
                        <div class="iq-team">
                            <img src="assets/img/JP.png" alt="Jodi Prevost" class="img-responsive center-block" style={{width:'50%'}}  />
                            <div class="iq-team-info text-center">
                                <h4 class="iq-font-white iq-tw-5">Jodi Prevost - Advisor</h4>
                                <ul style={{textAlign:'center', padding:"0 40px", fontSize:"14px"}}>
                                  <li>
                                    Jodi Prevost is an experienced audit partner at Frazier & Deeter leading audit methodology.
                                  
                                    Graduated from Georgia Institute of Technology, Jodi has extensive lease accounting experience and specializes in audits of not-for-profit, professional services and manufacturing & distribution entities and middle-market real estate and country clubs.
                                 
                                    Jodi ensures that LeaseVista is a collaborative tool for firms and their clients.
                                  </li>
                                </ul>                        
                            </div>
                        </div>
                    </div>
                    
                </div> */}
            </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Team;
