import React from "react";

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section id="about" className="about-us position-relative" style={{backgroundColor: '#005691'}}>
              <div className="container ptb-101">
                  <div className="row justify-content-center">
                      <div className="col-md-9 col-lg-8">
                          <div className="section-heading text-center text-white">
                              <h2 className="text-white">About LeaseVista</h2>
                              <p>LeaseVista is a Software As A Service (SaaS) product designed and developed by Leasing Standard Experts. The tool enables Corporations and Audit firms to manage their leases and ensure compliance with accounting standards.  With its “pay-as-you-go” model, LeaseVista is priced fairly, delighting its customers.</p>
                              <p>  
                              <b style={{fontSize: '20px'}}>LeaseVista is a user-friendly tool that makes adherence to  ASC 842, GASB 87/96, and IFRS 16 standards as easy as apple pie.</b>
                              </p>
                          </div>
                      </div>
                  </div>
              </div>  
        </section>
      </React.Fragment>
    );
  }
}

export default About;
