import React from "react";
import _data from "../../data";

class HeroSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hero: {}
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    this.setState({
      hero: _data.hero
    });
  }

  render() {
    return (
      <React.Fragment>
        <section className="position-relative bg-image pt-100" style={{backgroundColor: '#005691'}}>
            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-md-10 col-lg-6">
                        <div className="section-heading text-white py-5">
                            <h1 className="text-white font-weight-bold">Our innovative solution to your leasing challenges</h1>
                            <p>Compliance with the latest lease accounting standards.<br/> Built by experts who understand leasing standards.</p>
                            
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-9 col-lg-5">
                        <div className="img-wrap">
                            <img src="assets/img/1.png" alt="shape" className="img-fluid2" />
                        </div>
                    </div>
                </div>
                <div className="row mb-5">
                    <ul className="list-inline counter-wrap bg-white shadow">
                        <li className="list-inline-item">
                            <div className="single-counter text-center count-data">
                                <h6>ASC 842 Compliance</h6>
                            </div>
                        </li>
                        <li className="list-inline-item">
                            <div className="single-counter text-center count-data">
                                <h6>GASB 87 Compliance</h6>
                            </div>
                        </li>
                        <li className="list-inline-item">
                            <div className="single-counter text-center count-data">
                                <h6>IFRS 16 Compliance</h6>
                            </div>
                        </li>
                        <li className="list-inline-item">
                            <div className="single-counter text-center count-data">
                                <h6>Bulk Upload Leases</h6>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
      </React.Fragment>
    );
  }
}

export default HeroSection
