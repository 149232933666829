import React from "react";
import logoWhite from "../../img/logos/logo-long.png";

class Success extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: ''
        };
    }

    componentDidMount(){
        const search = window.location.search.replace('?', '');
        this.setState({type: search})
    }

    render() {
        return (
            <React.Fragment>
                
                <div className="main">
                    <section className="page-header-section ptb-100 bg-image full-height" image-overlay="8">
                        <div className="background-image-wraper" style={{backgroundImage: "url(assets/img/background.jpg)",opacity:0.4}}></div>
                        <div className="container">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-12">
                                    <div className="hero-content-left text-black">
                                    <a href="https://leasevista.com" ><img src={logoWhite} alt="logo" style={{width:"300px"}}/></a>
                                    <br/><br/><br/>
                                            {
                                            this.state.type === 'payment' || this.state.type === 'microdeposit' || this.state.type === 'demo' || this.state.type === 'payment_method' ?
                                                <h2 className="text-black">Thank You!</h2>
                                            :<h2 className="text-black">WECLOME TO LEASEVISTA!</h2>
                                            }
                                        <br/>
                                        <p className="lead">
                                            {
                                                (this.state.type === 'payment' || this.state.type === 'microdeposit') &&
                                                <>We have recorded your payment request.<br/></>
                                            }
                                            {
                                            this.state.type === 'payment' ?
                                                <>
                                                    You will receive a receipt from us as soon as your payment is confirmed. 
                                                </>
                                            : this.state.type === 'microdeposit' ?
                                                <>
                                                    To validate your ACH account, 
                                                    Stripe will make a micro-deposit of $0.01 to your account in 1-2 business days. 
                                                    Please find the validation code in your bank account statement under this transaction that looks like - SMXXXX. 
                                                    <br/>
                                                    Please use this code to validate your ACH account by following the steps mentioned in an email you'll get from us.
                                                </>
                                            : this.state.type === 'payment_method' ?
                                                <>
                                                    We have recorded your payment method.
                                                    To validate your ACH account, 
                                                    Stripe will make a micro-deposit of $0.01 to your account in 1-2 business days. 
                                                    Please find the validation code in your bank account statement under this transaction that looks like - SMXXXX. 
                                                    <br/>
                                                    Please use this code to validate your ACH account by following the steps mentioned in an email you'll get from us.
                                                </>
                                            : this.state.type === 'demo' ?
                                                <>
                                                    Your submission to book a demo was successful. Someone from our team will reach you shortly to discuss the date and times convenient for you for a demo of our tool.
                                                    <div>Click <a href="/">here</a> to return to the website.</div>
                                                </>
                                            :
                                                <>
                                                    <p>Your registration is successful! Thank you registering with LeaseVista. You should receive an e-mail with the login credentials shortly.</p>
                                                    <br/>
                                                    <p>Note: If you didn’t receive an e-mail, please check your Spam folder. If the e-mail is not found in the Spam folder as well, please contact your IT department to check and ensure that leasevista.com e-mail is not blocked by their e-mail filters.</p>
                                                    <br/>
                                                    <p>For any further questions/issues – please feel free to contact us at support@leasevista.com. We would be happy to assist you!</p>
                                                </>
                                            }
                                        </p>
                                        <br/>
                                        <p className="lead">
                                            Thank you!
                                            <br/>
                                            LeaseVista Team
                                        </p>
                                    </div>
                                </div>
                            </div>
                            
                      </div>
                    </section>
                </div>
               
            </React.Fragment>
        );
    }
}

export default Success;
