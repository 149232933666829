import React from "react";
import logoWhite from "../../img/logos/logo-long-white.png";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        email: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleFormValueChange(inputName, event) {
    let stateValue = {};
    stateValue[inputName] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState(stateValue);
  }

  handleSubmit(event) {
  }

  render() {
    return (
      <React.Fragment>
        <footer className={"footer-1 gradient-bg ptb-60 " + (this.props.withoutNewsletter && this.props.withoutNewsletter  === true ? "" : "footer-with-newsletter" )}>
        

        <div className="container">
            <div className="row">
                <div className="col-md-12 col-lg-6 mb-4 mb-md-4 mb-sm-4 mb-lg-0">
                    <a className="navbar-brand" href="/">
                      <img src={logoWhite} alt="" style={{width:'30%', height:'auto', marginBottom:'20px'}}/>
                    </a>
                    <br />
                    <p>A one-stop shop to automate and manage leases and be compliant with the latest accounting standards</p>
                </div>
                <div className="col-md-12 col-lg-6">
                    <div className="row mt-0">
                        <div className="col-sm-6 col-md-3 col-lg-6 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                            <h6 className="text-uppercase">IMPORTANT LINKS</h6>
                            <ul>
                                <li>
                                    <a href="/terms_and_conditions" target="_blank">Terms and Conditions</a>
                                </li>
                                <li>
                                    <a href="/privacy_policies" target="_blank">Privacy Policy</a>
                                </li>
                                <li>
                                    <a href="/disclaimers" target="_blank">Disclaimers</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <div className="footer-bottom py-3 gray-light-bg">
        <div className="container">
            <div className="row">
                <div className="col-md-6 col-lg-7">
                    <div className="copyright-wrap small-text">
                        <p className="mb-0">&copy; LeaseVista, All rights reserved</p>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
      </React.Fragment>
    );
  }
}

export default Footer
