import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Success from "../components/Payments/success";
import Failure from "../components/Payments/failure";

// importing all the themes
import Home from "../themes/home";
import RegisterForm from "../themes/registerForm";
import TermsAndConditions from "../themes/termsAndConditions";
import PrivacyPolicy from "../themes/privacyPolicy";
import Disclaimers from "../themes/disclaimers";
import Checkout from "../themes/checkout";
import BookDemo from "../themes/bookDemo";

export default class Routes extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            <Route exact path="/success" component={Success}/>
            <Route exact path="/failed" component={Failure}/>
            <Route exact path="/checkout" component={Checkout}/>
            <Route path="/" component={() => { window.location = 'https://customer.leasevista.com'; return null;} } />
            {/* <Route exact path="/home" component={Home} />
            <Route exact path="/register" component={RegisterForm}/>
            <Route exact path="/terms_and_conditions" component={TermsAndConditions}/>
            <Route exact path="/privacy_policies" component={PrivacyPolicy}/>
            <Route exact path="/disclaimers" component={Disclaimers}/>*/}
            <Route exact path="/checkout" component={Checkout}/>
            <Route exact path="/book_demo" component={BookDemo}/>
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}
