import React from "react";

class Feature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <div id="features" className="feature-section ptb-101 ">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-9 col-lg-9">
                        <div className="section-heading text-center mb-5">
                            <h2>Partner with LeaseVista</h2>
                            <p> Our solution is built with Accounting Advisory firms in mind. We’ll help you through leasing
                                implementation and equip you with the tools to serve your clients well. Not only during implementation
                                but also the years ahead.</p>
                            <h2>Partner with LeaseVista</h2>
                            <p> Our solution is custom-tailored to serve both Accounting Advisory firms and commercial & governmental organizations. We will help you with initial implementation as well as maintenance, and support for all your leasing needs.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-6 col-lg-3">
                        <div className="card border-0 single-promo-card single-promo-hover p-2 mt-4 h-4">
                            <div className="card-body">
                                <div className="pb-2">
                                    <span className="fas fa-concierge-bell icon-size-md color-secondary"></span>
                                </div>
                                <div className="pt-2 pb-3">
                                    <h5>Modular</h5>
                                    <p className="mb-0">All components are built in combination.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <div className="card border-0 single-promo-card single-promo-hover p-2 mt-4 h-4">
                            <div className="card-body">
                                <div className="pb-2">
                                    <span className="fas fa-window-restore icon-size-md color-secondary"></span>
                                </div>
                                <div className="pt-2 pb-3">
                                    <h5>Responsive</h5>
                                    <p className="mb-0">Quick is optimized to work for most devices.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <div className="card border-0 single-promo-card single-promo-hover p-2 mt-4 h-4 h-4">
                            <div className="card-body">
                                <div className="pb-2">
                                    <span className="fas fa-sync-alt icon-size-md color-secondary"></span>
                                </div>
                                <div className="pt-2 pb-3">
                                    <h5>Scalable</h5>
                                    <p className="mb-0">Remain consistent while developing new features.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <div className="card border-0 single-promo-card single-promo-hover p-2 mt-4 h-4">
                            <div className="card-body">
                                <div className="pb-2">
                                    <span className="fas fa-bezier-curve icon-size-md color-secondary"></span>
                                </div>
                                <div className="pt-2 pb-3">
                                    <h5>Customizable</h5>
                                    <p className="mb-0">Change a few variables &amp; theme adapts.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </React.Fragment>
    );
  }
}

export default Feature;
