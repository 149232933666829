import React, {useEffect, useState} from "react";
import Footer from '../components/Footer/homeFooter';
import ScrollToTopOnMount from "../components/ScrollToTop/index";
import HeaderWithHomeIcon from "../components/Header/headerWithIcon";
import {useHistory} from "react-router-dom"
import {NotificationManager} from 'react-notifications';
import axios from "axios";
import AppConfig from "../constants/config";
import moment from "moment";

const Checkout = (props) => {
    const [data, setData] = useState({});
    const [id, setId] = useState("");
    const [paymentMethod, setPaymentMethod] = useState("");
    const [finalAmount, setFinalAmount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [customerInfo, setCustomerInfo] = useState({})
    const history = useHistory();

    useEffect(() => {
        setId(new URLSearchParams(history.location.search).get('id'))
        // eslint-disable-next-line
    },[])

    useEffect(() => {
        if(paymentMethod === 'card'){
            const amount = data ? Number(data.charged_amount || 0) : 0;
            const total = amount + (amount * 0.03)
            setFinalAmount(Number(total.toFixed(2)))
        }
        else{
            const amount = data ? Number(data.charged_amount || 0) : 0;
            setFinalAmount(Number(amount.toFixed(2)))
        }
    },[paymentMethod, data])

    useEffect(() => {
        if(data && !data.is_paid && data.customer_id && data.customer_id.card_no){
            setPaymentMethod('card')
        }
    }, [data])

    useEffect(() => {
        if(id){
            getCustomerData();
        }
        // eslint-disable-next-line
    },[id])

    const getCustomerData = async() => {
        try {
            const {data} = await axios.get(`${AppConfig.baseUrl}/billLog/getBillingDetails?id=${id}`);
            if(!data.error){
                if(data.billData && data.billData.customer_id && data.billData.customer_id.verification_url){
                    window.location.href = data.billData.customer_id.verification_url
                }else{
                    setData(data.billData ? data.billData : {})
                    setCustomerInfo(data.custInfoData ? data.custInfoData : {})
                }
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            NotificationManager.error(error.message || 'Something went wrong');
            history.push("/home")
        }
    }

    const handleChange = (e) => {
        setPaymentMethod(e.target.value)
    }

    const handleClick = async() => {
        setLoading(true)
        try {
            const {data: resData} = await axios.post(`${AppConfig.baseUrl}/stripe/checkout`, {
                amount: finalAmount, 
                customer_id: data.customer_id && data.customer_id._id,
                bill_id: data._id,
                paymentMethod
            });
            if(!resData.error){
                window.location.href = resData.session.url
            }
            else{
                throw new Error(resData.title);
            }
        } catch (error) {
            NotificationManager.error(error.message || 'Something went wrong');
            history.push("/home")
        }
        setLoading(false)
    }

    return(
        <React.Fragment>
            <ScrollToTopOnMount/>
            <HeaderWithHomeIcon/>
            <div>
                <div className="position-relative pt-100 ">
                    <div className="container">
                        <div className="row justify-content-center josac" style={{height:'75vh'}}>
                            {
                                data && data.in_progress ?
                                    <div className="vh_center">Your payment is currently in progress. You will receive email as soon as payment is completed. </div>
                                : data && data.is_online ?
                                <div className="col-md-9 col-lg-9">
                                    {
                                        data && data.is_paid ?
                                        <>
                                            <div className="section-heading text-left mb-5">
                                                <h2>Receipt Summary</h2>
                                            </div>
                                            <div className="mb-3">
                                                Hi {customerInfo.admin_name}, you have already paid your invoice. Following is the summary of the monthly receipt for {data && data.invoice_date ? moment(data.invoice_date).utc().subtract(1, 'month').format('MMMM, YYYY') : ''}:
                                            </div>
                                        </>
                                        : 
                                        <>
                                            <div className="section-heading text-left mb-5">
                                                <h2>Checkout Summary</h2>
                                            </div>
                                            <div className="mb-3">
                                                Hi {customerInfo.admin_name}, following is the summary of the monthly invoice for {data && data.invoice_date ? moment(data.invoice_date).utc().subtract(1, 'month').format('MMMM, YYYY') : ''}:
                                            </div>
                                        </>
                                    }
                                    <table className="mb-3">
                                        <tr>
                                            <td>Number of Active Leases:</td>
                                            <td>{data ? data.active_leases : ''}</td>
                                        </tr>
                                        <tr>
                                            <td>Number of Inactive/Expired Leases:</td>
                                            <td>{data ? data.inactive_leases : ''}</td>
                                        </tr>
                                        <tr>
                                            <td>Number of Terminated Leases:</td>
                                            <td>{data ? data.terminated_leases : ''}</td>
                                        </tr>
                                        <tr>
                                            <td>Number of Drafted Leases:</td>
                                            <td>{data ? data.drafted_leases : ''}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Number of Leases:</td>
                                            <td>{data ? data.total_leases : ''}</td>
                                        </tr>
                                        <tr>
                                            <td>{data && data.invoice_date ? moment(data.invoice_date).utc().subtract(1, 'month').format('MMMM') : ''} Month Charge Amount:</td>
                                            <td>{data && data.charged_amount ? "$" + Number(Number(data.charged_amount).toFixed(2)) : ''}</td>
                                        </tr>
                                        {
                                            paymentMethod === 'card' && 
                                            <tr>
                                                <td>Credit card processing fees:</td>
                                                <td>{data ? "$" + Number((finalAmount - Number(data.charged_amount || 0)).toFixed(2)) : 0}</td>
                                            </tr>
                                        }
                                        <tr>
                                            {
                                                data && data.is_paid ?
                                                <td><b>Final Amount Charged:</b></td>
                                                :
                                                <td><b>Final Amount To Charge:</b></td>
                                            }
                                            <td><b>${finalAmount}</b></td>
                                        </tr>
                                    </table>
                                    {
                                        data && data.is_paid ?
                                        <></> :
                                        <>
                                            <div className="mb-3">
                                                Please select payment method:<br/>
                                                <input type="radio" id="card" name="payment_method" value="card" onChange={handleChange}/>
                                                <label style={{marginLeft:'5px', marginRight: '15px'}} for="card">Card</label>
                                                <input type="radio" id="us_bank_account" name="payment_method" value="us_bank_account" onChange={handleChange}/>
                                                <label style={{marginLeft:'5px', marginRight: '15px'}} for="us_bank_account">Bank</label>
                                            </div>
                                            <button type="button" className="btn btn-brand-02" id="btnContactUs" disabled={!paymentMethod || loading} onClick={handleClick} >
                                                PAY NOW
                                            </button>
                                        </>
                                    }
                                </div>
                                :
                                <div className="vh_center">
                                    This link is not valid
                                </div>
                            }                            
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    )
}

export default Checkout;