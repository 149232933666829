import React from "react";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="home-footer py-3 gradient-bg txt-white">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-7">
                <div className="copyright-wrap small-text text-white" >
                  <p className="mb-0">
                    &copy; LeaseVista, All rights reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Footer;
