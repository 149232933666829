import React, { Component } from "react";
import Header from "../components/Header/header";
import Hero from "../components/HeroSection/HeroSection";
import AboutUs from "../components/AboutUs/AboutUs";
import Feature from "../components/Features/Features";
import Pricing from "../components/Pricing";
//import Faq from "../components/Faq";
//import Testimonial from "../components/Testimonial";
import Team from "../components/Team";
import ContactSection from "../components/Contact";
import FooterSection from "../components/Footer";

class Theme extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <div className="main">
          <Hero />
          <Feature/>
          <AboutUs />
          <Pricing isWhite={true} />
          <Team />
          <ContactSection />
        </div>
        <FooterSection />
      </React.Fragment>
    );
  }
}

export default Theme;
