import React from "react";
import Routes from "./routers";
import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

function App() {
  return (
    <React.Fragment>
      <Routes />
      <NotificationContainer/>
    </React.Fragment>
  );
}

export default App;
