import React, { useState } from "react";
import { object, string } from "yup";
import Footer from '../components/Footer/index';
import ScrollToTopOnMount from "../components/ScrollToTop/index";
import HeaderWithHomeIcon from "../components/Header/headerWithIcon";
import axios from "axios";
import AppConfig from "../constants/config";
import { useFormik } from "formik";
import {NotificationManager} from 'react-notifications';
import InputMask from 'react-input-mask'

const BookDemo = (props) => {
    const [loading, setLoading] = useState(false);

	const handleSubmit = async (values) => {
        setLoading(true);
        try {
            const {data} = await axios.post(`${AppConfig.baseUrl}/contact_inquiries/bookDemo`, values);
            if(!data.error){
                window.location.href = "/success?demo"
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            NotificationManager.error(error.message || 'Something went wrong');
        }
        setLoading(false);
	};

    const registerSchema = object().shape({
        name: string().required("* Name is required"),
        email: string().email("Please enter valid email").required("* Email is required"),
    });

	const formik = useFormik({
		initialValues: {
			name: "",
            email: "",
            phone_number: "",
            work_location: "",
            leases: "",
            company: "",
            message: "",
            email_a_copy: false,
		},
		validationSchema: registerSchema,
		onSubmit: handleSubmit
	});
    
    return(
        <React.Fragment>
            <ScrollToTopOnMount/>
            <div className="gray-light-bg">
                <HeaderWithHomeIcon/>
                <div className="position-relative pt-100 ">
                    <div className="container">
                        <div className="row justify-content-center josac">
                            <div className="col-md-9 col-lg-9">
                                <div className="section-heading text-center mb-5">
                                    <h2>Book A Demo</h2>
                                    <p>Kindly fill up the following form to book a demo for LeaseVista.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row justify-content-around josaco">
                        <div className="col-12 pb-3 mb-4 message-box d-none alert alert-success"></div>
                        <div className="col-md-12 col-lg-10 mb-5 mb-md-5 mb-sm-5 mb-lg-0">
                            <div className="contact-us-form rounded p-5">
                                <form action="" method="POST" id="contactForm" className="contact-us-form" onSubmit={formik.handleSubmit}>
                                    <div className="form-row">
                                        <div className="form-group col-lg-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="name" placeholder="Name" 
                                                    onChange={formik.handleChange}
                                                    onBlur={() => formik.setFieldTouched("name")}
                                                    value={formik.values.name}
                                                />
                                                {formik.touched.name && formik.errors.name && (
                                                    <div className="text-danger">{formik.touched.name && formik.errors.name}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <div className="form-group">
                                                <input type="email" className="form-control" name="email" placeholder="Email"
                                                    onChange={formik.handleChange}
                                                    onBlur={() => formik.setFieldTouched("email")}
                                                    value={formik.values.email}
                                                />
                                                {formik.touched.email && formik.errors.email && (
                                                    <div className="text-danger">{formik.touched.email && formik.errors.email}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <div className="form-group">
                                                <InputMask
                                                    mask="(999)999-9999"
                                                    value={formik.values.phone_number}
                                                    className="form-control input-mask"
                                                    onChange={formik.handleChange} 
                                                    name="phone_number" 
                                                    placeholder="Phone number"
                                                    onBlur={() => formik.setFieldTouched("phone_number")}
                                                />
                                                {formik.touched.phone_number && formik.errors.phone_number && (
                                                    <div className="text-danger">{formik.touched.phone_number && formik.errors.phone_number}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="work_location" placeholder="Work location" 
                                                    onChange={formik.handleChange}
                                                    onBlur={() => formik.setFieldTouched("work_location")}
                                                    value={formik.values.work_location}
                                                />
                                                {formik.touched.work_location && formik.errors.work_location && (
                                                    <div className="text-danger">{formik.touched.work_location && formik.errors.work_location}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <div className="form-group">
                                                <input type="number" className="form-control" name="leases" placeholder="Estimated no. of leases" 
                                                    onChange={formik.handleChange}
                                                    onBlur={() => formik.setFieldTouched("leases")}
                                                    value={formik.values.leases}
                                                />
                                                {formik.touched.leases && formik.errors.leases && (
                                                    <div className="text-danger">{formik.touched.leases && formik.errors.leases}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="company" placeholder="Company name" 
                                                    onChange={formik.handleChange}
                                                    onBlur={() => formik.setFieldTouched("company")}
                                                    value={formik.values.company}
                                                />
                                                {formik.touched.company && formik.errors.company && (
                                                    <div className="text-danger">{formik.touched.company && formik.errors.company}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group col-sm-12">
                                            <div className="form-group">
                                                <textarea className="form-control" name="message" placeholder="Message" rows={3}
                                                    onChange={formik.handleChange}
                                                    onBlur={() => formik.setFieldTouched("message")}
                                                    value={formik.values.message}
                                                />
                                                {formik.touched.message && formik.errors.message && (
                                                    <div className="text-danger">{formik.touched.message && formik.errors.message}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group col-sm-12">
                                            <div className="form-group ">
                                                <input type="checkbox" id="email_a_copy" name="email_a_copy" onChange={formik.handleChange} checked={formik.values.email_a_copy} />&nbsp;
                                                <label for="email_a_copy">Email me a copy. </label>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <button type="submit" className="btn btn-brand-02" id="btnContactUs" disabled={loading} >
                                                {
                                                    loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                                }
                                                SUBMIT
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </React.Fragment>
    )
}

export default BookDemo;