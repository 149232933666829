import React from "react";
import axios from "axios";
import AppConfig from "../../constants/config";

export default class Pricing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            prices: []
        };
    }

    componentDidMount = async () => {
        try {
            const { data } = await axios.get(`${AppConfig.baseUrl}/pricing?code=DISC0`);
            if (!data.error) {
                this.setState({ prices: data.allPrices })
            } else {
                throw new Error(data.title || 'Something went wrong');
            }
        } catch (error) {
            console.log('object weewerw', error);
        }
    }

    render() {
        return (
            <React.Fragment>
                <section id="pricing" className={"pricing-section ptb-100 " + (this.props.isWhite && this.props.isWhite === true ? '' : 'gray-light-bg')}>
                    <div className="container">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="heading-title section-heading text-center mb-4">
                                    <h2 class="title iq-tw-6">Our Pricing</h2>
                                    <div class="divider"></div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-sm-12">
                                <div className="section-heading">
                                    <ul style={{ listStyleType: 'disc' }}>
                                        <li>
                                            We are a true Software as a Service (SaaS) Company. You will ONLY Pay for what you use.
                                        </li>
                                        <li>
                                            Our minimum pricing starts at $375 per month which covers up to 50 leases without any restrictions on the number of users. The price per lease drops, as you add more leases per your need.
                                        </li>
                                        <li>
                                            Customers are billed at the end of each month, calculated based on the number of leases in use.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-md-center justify-content-center mt-4">
                            <table className="pricing-table text-center">
                                <tr className="pricing-table-main-heading">
                                    <td colSpan={this.state.prices.length + 1} style={{border: '1px solid black'}}>No. of Leases in Use</td>
                                </tr>
                                <tr className="pricing-table-heading">
                                    <td>{""}</td>
                                    <td>{"<=50"}</td>
                                    <td>51 – 100</td>
                                    <td>100 – 250</td>
                                    <td>250 – 500</td>
                                    <td>500+</td>
                                </tr>
                                <tr>
                                    <td className="pricing-table-heading">Price per Lease **</td>
                                    {
                                        this.state.prices.map(v => <td>${v.monthly_price}/Month*<br />${v.yearly_price}/Annum*</td>)
                                    }
                                </tr>
                            </table>
                            <div className="row justify-content-center mt-4">
                                <div className="col-md-12 col-lg-12">
                                    <div>
                                        <ul style={{ listStyleType: 'disc' }}>
                                            <li>
                                                <b>Note: </b> Minimum Billing of $416.5/Month will be charged regardless of the usage, below 50 leases.
                                            </li>
                                            <li>
                                                ** Additional implementation fees will apply.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-12">
                                    <div className="section-heading text-center mt-4">
                                    <b>Introductory Pricing: </b> Register today to receive <u><b>10 % Discount</b></u>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-12">
                                    <div className="section-heading text-center mt-4">
                                        <a type="button" className="btn btn-brand-02" id="btnContactUs" href="/register" target="_blank" >
                                            REGISTER
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-12">
                                    <div className="section-heading text-center mt-4">
                                        Send us an email at <u><a href="mailto:pricing@leasevista.com" target="_blank" rel="noopener noreferrer">pricing@leasevista.com</a></u> if you have any questions about pricing.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
