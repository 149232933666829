import React from "react";
import logoWhite from "../../img/logos/logo-long-white.png";
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {activeLink: 'home'}
  }

  handleClick = (link) => {
    this.setState({activeLink: link})
  };
  render() {
    return (
      <React.Fragment>
        <header className="header">
          <nav className="navbar navbar-expand-lg fixed-top bg-trasparent">
              <div className="container">
                  <a className="navbar-brand" href="/">
                    <img src={logoWhite} alt="" className="img-fluid"/>
                  </a>
                  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                      <span className="ti-menu"></span>
                  </button>

                  <div className="collapse navbar-collapse h-auto" id="navbarSupportedContent">
                      <ul className="navbar-nav ml-auto menu">
                          <li><a href="home" className={`page-scroll nav-link ${this.state.activeLink === 'home' ? 'active' : ''}`} onClick={() => this.handleClick('home')}> <b>HOME</b></a></li>
                          <li><a href="#features" className={`page-scroll nav-link ${this.state.activeLink === 'features' ? 'active' : ''}`} onClick={() => this.handleClick('features')}><b>FEATURES</b></a></li>
                          <li><a href="#about" className={`page-scroll nav-link ${this.state.activeLink === 'about' ? 'active' : ''}`} onClick={() => this.handleClick('about')}><b>ABOUT</b></a></li>
                          <li><a href="#pricing" className={`page-scroll nav-link ${this.state.activeLink === 'pricing' ? 'active' : ''}`} onClick={() => this.handleClick('pricing')}><b>PRICING</b></a></li>
                          <li><a href="#contact" className={`page-scroll nav-link ${this.state.activeLink === 'contact' ? 'active' : ''}`} onClick={() => this.handleClick('contact')}><b>CONTACT</b></a></li>
                          <li><a href="https://customer.leasevista.com/login" className={`page-scroll nav-link ${this.state.activeLink === 'login' ? 'active' : ''}`} onClick={() => this.handleClick('login')}><b>LOGIN</b></a></li>
                      </ul>
                  </div>
              </div>
          </nav>
      </header>
      </React.Fragment>
    );
  }
}

export default Header
