import React from "react";
import './headerIcon.module.css'
import logoWhite from "../../img/logos/logo-long-white.png";


class HeaderWithHomeIcon extends React.Component {

  render() {
    return (
      <React.Fragment>
        <header className="headerIcon">
          <nav className="navbar navbar-expand-lg fixed-top bg-trasparent">
            <div className="container">
                <a className="navbar-brand" href="/">
                  <img src={logoWhite} alt="" style={{width:'15%', height:'auto'}}/>
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="ti-menu"></span>
                </button>
                <div className="collapse navbar-collapse h-auto" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto menu">
                       <li><a href="home"><i className="fas fa-home"></i></a></li>
                    </ul>
                </div>
            </div>
          </nav>
      </header>
      </React.Fragment>
    );
  }
}

export default HeaderWithHomeIcon
